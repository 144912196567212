import React, { PureComponent, Fragment } from 'react'
import classNames from 'classnames'
import PropTypes from 'prop-types'
import Navigation from '../Navigation'
import Content from '../component/Primitive/Content'
import Icon from '../../../site/layout/component/Primitive/Icon'

class Header extends PureComponent {
  constructor (props) {
    super(props)

    this.state = {
      isOpen: false
    }

    this.handleClick = this.handleClick.bind(this)
  }

  componentDidUpdate () {
    document.body.classList.toggle('is-menu-open', this.state.isOpen)
  }

  componentWillUnmount () {
    document.body.classList.remove('is-menu-open')
  }

  handleClick () {
    this.setState({ isOpen: !this.state.isOpen })
  }

  render () {
    const { links, currentSection, products } = this.props
    const { isOpen } = this.state
    return (
      <>
        <header role="banner" className={classNames('Header')}>
          <Content>
            <div className="Header__container">
              <div className="Header__content">
                <a href="/" className="Header__logo">
                  <Icon type="Logo" width={112} />
                </a>
                <div className="Header__contacts">
                  <a href="tel:+441536527150" className="Header__telephone">
                    +44 (0)1536 527150
                    <Icon
                      className="Header__icon"
                      type="Phone"
                      width={23}
                      height={23}
                    />
                  </a>
                  <a
                    href="mailto:enquiry@redfishuk.com"
                    className="Header__email"
                  >
                    enquiry@redfishuk.com
                    <Icon
                      className="Header__icon"
                      type="Mail"
                      width={23}
                      height={23}
                    />
                  </a>
                </div>
              </div>
              <button
                className={classNames('Header__button')}
                onClick={this.handleClick}
              >
                <Icon
                  type={isOpen ? 'Close' : 'Hamburger'}
                  width={23}
                  height={23}
                />
              </button>
            </div>
          </Content>
        </header>
        {isOpen && links && links.length && (
          <div
            className="Header__navigation"
            id="navigation"
            role="navigation"
            aria-label="Primary Navigation"
          >
            <Navigation
              primaryNavigation
              currentSection={currentSection}
              links={links}
            />
            <strong>Our Products</strong>
            <Navigation
              secondaryNavigation
              currentSection={currentSection}
              links={products}
            />
          </div>
        )}
      </>
    )
  }
}

Header.propTypes = {
  links: PropTypes.array,
  currentSection: PropTypes.string,
  products: PropTypes.array
}

export default Header
