import React, { PureComponent } from 'react'
import PropTypes from 'prop-types'
import classNames from 'classnames'

class Content extends PureComponent {
  render () {
    const { children, width, centered, gutter, className } = this.props
    return (
      <div className={classNames(
        'Content',
        width && width,
        centered && 'center',
        className,
        gutter && 'gutter'
      )}
      >
        {children}
      </div>
    )
  }
}

Content.propTypes = {
  children: PropTypes.node,
  className: PropTypes.string,
  width: PropTypes.string,
  centered: PropTypes.bool,
  gutter: PropTypes.bool
}

export default Content
