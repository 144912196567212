import React from 'react'
import ReactDOM from 'react-dom'
import Header from '../../../site/layout/Header'

const debug = require('debug')('header')

export default () => {
  debug('init')
  document.addEventListener('DOMContentLoaded', () => {
    document.querySelectorAll('.js-header').forEach(appNode => {
      const props = JSON.parse(appNode.getAttribute('data-props') || '{}')
      ReactDOM.render(
        <Header {...props} />,
        appNode
      )
    })
  })
}
