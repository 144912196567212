import React from 'react'
import ReactDOM from 'react-dom'
import Enquiry from '../../../site/layout/component/Enquiry/index'
import createFormSubmissionContainer from '../../lib/form-submission'
import required from '@clocklimited/validity-required'
import isEmail from '@clocklimited/validity-email'
import schemata from 'schemata'
import debug from 'debug'

const schema = schemata({
  name: { type: String, validators: [ required ] },
  email: { type: String, validators: [ required, isEmail ] },
  message: { type: String, validators: [ required ] },
  captcha: {
    type: String,
    validators: [ required.setFailureMessage('Captcha must be checked') ]
  }
})

const FormSubmissionContainer = createFormSubmissionContainer({
  Component: props => <Enquiry {...props} />,
  schema: schema,
  apiUrl: '/enquiry',
  errorMessage:
    'Please check your details and try again. If the problem persists, please get in touch via email.',
  successMessage: 'Your enquiry has been received, we will be in touch shortly.'
})

export default () => {
  document.addEventListener('DOMContentLoaded', () => {
    const appNode = document.getElementById('enquiry')
    if (!appNode) {
      return debug('No enquiry node found. Skipping.')
    }
    const props = JSON.parse(appNode.getAttribute('data-props') || '{}')
    ReactDOM.render(
      <FormSubmissionContainer {...props} />,
      appNode
    )
  })
}
