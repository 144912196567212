import React from 'react'
import PropTypes from 'prop-types'

import SingleCheckControl from './SingleCheck'

const CheckControl = ({ options, ...other }) => {
  if (options.length === 1) {
    return <SingleCheckControl {...other} option={options[0]} />
  }

  return (
    <ul className="control--check-list list--unstyled">
      <div className="grid grid--flex">
        {options.map((option, i) => (
          <div
            className="grid__item one-half tablet-one-third tablet-landscape-one-quarter desktop-one-fifth"
            key={i}
          >
            <li className="filter-box__list-item">
              <SingleCheckControl {...other} option={option} />
            </li>
          </div>
        ))}
      </div>
    </ul>
  )
}

CheckControl.propTypes = {
  options: PropTypes.array.isRequired
}

export default CheckControl
