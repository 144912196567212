import React, { PureComponent } from 'react'
import PropTypes, { number, oneOf, string } from 'prop-types'
import classNames from 'classnames'
import svgDimensionsFormatter from '../../../lib/svg-dimensions-formatter'
import ratioScaler from '../../../lib/ratio-scaler'

import Svgs from './Svgs'
import VisuallyHidden from '../VisuallyHidden'

export class Icon extends PureComponent {
  render () {
    const { a11yText, className, type, height, width, status, refNode, fill, onClick } = this.props
    const SvgType = Svgs[type]
    if (!SvgType) return null
    const targetDimensions = { width, height }
    const nativeDimensions = svgDimensionsFormatter(SvgType)
    const ratioDimensions = ratioScaler(
      targetDimensions,
      nativeDimensions
    )

    return (
      <span
        ref={refNode}
        onClick={onClick}
        className={classNames('Icon', status && status, className, fill && fill)}
        aria-hidden={!a11yText ? 'true' : null}
        style={{
          width: `${ratioDimensions.width}px`,
          height: `${ratioDimensions.height}px`,
          lineHeight: `${ratioDimensions.height}px`
        }}
      >
        {!!a11yText && <VisuallyHidden>{a11yText}</VisuallyHidden>}
        <SvgType />
      </span>
    )
  }
}

Icon.displayName = 'Icon'

Icon.propTypes = {
  a11yText: string,
  fill: string,
  className: string,
  type: oneOf(
    Object.keys(Svgs)
  ).isRequired,
  height: number,
  width: number,
  status: oneOf([ 'error', 'notice', 'success', 'warning' ]),
  refNode: PropTypes.func,
  onClick: PropTypes.func
}

export default Icon
