import React, { PureComponent } from 'react'
import classNames from 'classnames'
import PropTypes from 'prop-types'
import SmartLink from '../component/Primitive/SmartLink'

class Navigation extends PureComponent {
  render () {
    const { links = {}, currentSection, footer, primaryNavigation, secondaryNavigation, productPage } = this.props
    const classes = classNames(
      'Navigation',
      footer && 'Navigation--footer',
      primaryNavigation && 'Navigation--primary',
      secondaryNavigation && 'Navigation--secondary',
      footer && (productPage && 'Navigation--footer-product-page')
    )
    if (!links.length) return null

    return (
      <ul className={classes}>
        {links.map((link, index) => (
          <li key={link._id || index}>
            <SmartLink href={`/${((link.name).replace(/\s+/g, '-')).toLowerCase()}`} active={currentSection === `/${((link.name).replace(/\s+/g, '-')).toLowerCase()}`} className={secondaryNavigation && (classNames(((link.name).toLowerCase()) + 'Navigation'))}>{link.name}</SmartLink>
          </li>
        ))}
      </ul>
    )
  }
}

Navigation.propTypes = {
  links: PropTypes.array,
  footer: PropTypes.bool,
  primaryNavigation: PropTypes.bool,
  secondaryNavigation: PropTypes.bool,
  productPage: PropTypes.bool,
  currentSection: PropTypes.string
}

export default Navigation
