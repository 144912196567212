import React, { PureComponent } from 'react'
import classNames from 'classnames'
import PropTypes from 'prop-types'
import ReCAPTCHA from 'react-google-recaptcha'
import Field from '../Primitive/Field/component/Field'
import ButtonStandard from '../Primitive/ButtonStandard'
import VisuallyHidden from '../Primitive/VisuallyHidden'
import SmartLink from '../Primitive/SmartLink'
import Address from '../Address'
class Enquiry extends PureComponent {
  constructor (props) {
    super(props)
    this.handleVerifyCallback = this.handleVerifyCallback.bind(this)
    this.setValues = this.props.setValues
  }

  handleVerifyCallback (response) {
    this.setValues({
      captcha: response
    })
  }

  render () {
    const {
      onSubmit,
      onChange,
      successMessage,
      error = {},
      errorMessage,
      siteKey
    } = this.props
    return (
      <div className={classNames('Enquiry')}>
        <div className="Enquiry__Form">
          {successMessage && (
            <p className="Enquiry__Status success"> {successMessage} </p>
          )}
          {errorMessage && (
            <p className="Enquiry__Status error"> {errorMessage} </p>
          )}
          <Field
            name="name"
            type="text"
            label="Name"
            placeholder="Your name"
            onChange={onChange}
            error={error.name}
          />
          <Field
            name="email"
            type="text"
            label="Email address"
            placeholder="Your email address"
            onChange={onChange}
            error={error.email}
          />
          <Field
            name="message"
            type="textarea"
            label="How can we help?"
            placeholder="Your message"
            rows="10"
            onChange={onChange}
            error={error.message}
          />
          <div className="Enquiry__Captcha">
            <ReCAPTCHA
              sitekey={siteKey}
              onChange={this.handleVerifyCallback}
            />
          </div>
          <VisuallyHidden>
            <Field
              name="captcha"
              type="checkbox"
              options={[]}
              error={error.captcha}
            />
          </VisuallyHidden>
          <ButtonStandard
            type="submit"
            value="Submit"
            name="Submit"
            buttonStyle="standard"
            onClick={onSubmit}
          >
            Get in touch
          </ButtonStandard>
        </div>
        <div className="Enquiry__Details">
          <p>Email us: </p>
          <SmartLink
            className="Enquiry__Details__Email"
            href="mailto:enquiry@redfishuk.com"
          >
            enquiry@redfishuk.com
          </SmartLink>
          <Address
            addressLocality="Kettering"
            postalCode="NN15 6WJ"
            streetAddress="Unit 6, Kings Court"
          />
        </div>
      </div>
    )
  }
}

Enquiry.propTypes = {
  onSubmit: PropTypes.func,
  onChange: PropTypes.func,
  setValues: PropTypes.func,
  error: PropTypes.object,
  successMessage: PropTypes.string,
  siteKey: PropTypes.string,
  errorMessage: PropTypes.string
}

export default Enquiry
