import React from 'react'
import ReactDOM from 'react-dom'
import BackToTop from '../../../site/layout/component/BackToTop'

const debug = require('debug')('back-to-top')

export default () => {
  debug('init')
  document.addEventListener('DOMContentLoaded', () => {
    document.querySelectorAll('.js-back-to-top').forEach(appNode => {
      ReactDOM.render(
        <BackToTop />,
        appNode
      )
    })
  })
}
