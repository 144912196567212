import breakpoints from './breakpoints'
import createBreakpointManager from 'break'
import regg from 'regg'
import * as Sentry from '@sentry/browser'

import 'classlist-polyfill'
import 'element-closest'
import 'nodelist-foreach-polyfill'
import { EventEmitter } from 'events'
import config from '../whitelist-config.json'
import elementDataSet from 'element-dataset'

const env = process.env.NODE_ENV || 'development'

const debug = require('debug')('base')
debug('Init')
elementDataSet()
debug('NODE_ENV', env)

const bm = createBreakpointManager()
const serviceLocator = regg()

serviceLocator.register('config', config)

if (process.env.NODE_ENV !== 'development') {
  Sentry.init({
    dsn: config.sentry.site.dsn,
    environment: process.env.SENTRY_ENV,
    release: config.release
  })
  Sentry.configureScope((scope) => {
    scope.setTag('application', 'browser')
  })
  window.Sentry = Sentry
} else {
  window.Sentry = {
    captureException (e) {
      console.error(e)
    }
  }
}

// Look for an encoded article
const encodedEntityNode = document.querySelector('#encoded-entity')
if (encodedEntityNode) {
  const entityJson = encodedEntityNode.innerHTML
  try {
    window.__entity = JSON.parse(entityJson)
  } catch (e) {
    Sentry.captureException(e)
  }
} else {
  window.__entity = {}
}

const components = [
  require('./header/init').default,
  require('./back-to-top/init').default,
  require('./load-more/init').default,
  require('./enquiry/init').default,
  require('./apply/init').default
]

process.nextTick(() => {
  bm.add('mobile', breakpoints.mobileMq)
  bm.add('mobileNav', breakpoints.mobileNavMq)
})

serviceLocator.instancePath =
  document.querySelector('body').dataset.instancePath
serviceLocator.subscribeUrl =
  document.querySelector('body').dataset.subscribeUrl
serviceLocator.config = config
serviceLocator.register('breakpointManager', bm)

window.messageBus = new EventEmitter()

components.forEach((component) => component(serviceLocator, window))
