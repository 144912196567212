import React from 'react'
import PropTypes from 'prop-types'
import classNames from 'classnames'

import Control from '../../Control/Control'

const StandardCheckField = ({
  showRequired = true,
  required,
  assistance,
  className,
  controlClassName,
  error,
  id,
  label,
  modifiers,
  name,
  ...other
}) => {
  const fieldId = `field--${id || name}`
  const questionId = `question--${id || name}`
  const fieldClasses = classNames(
    'field',
    'field--standard-check',
    modifiers && modifiers.map((modifierClass) => `field--${modifierClass}`),
    className,
    { 'field--error': error }
  )

  return (
    <div
      aria-labelledby={label && questionId}
      className={fieldClasses}
      id={fieldId}
      role="group"
    >
      {label && (
        <div className="field__question" id={questionId}>
          {label}
          {showRequired && required && (
            <span>
              &nbsp;<abbr title="This field is required">*</abbr>
            </span>
          )}
        </div>
      )}
      <div className="field__answer">
        <Control
          className={controlClassName}
          error={error}
          modifiers={modifiers}
          name={name}
          required={required}
          {...other}
        />
      </div>
      {assistance && <div className="field__assistance">{assistance}</div>}
      {error && <div className="field__feedback">{error}</div>}
    </div>
  )
}

StandardCheckField.defaultProps = {}

StandardCheckField.propTypes = {
  showRequired: PropTypes.bool,
  required: PropTypes.bool,
  assistance: PropTypes.string,
  className: PropTypes.string,
  controlClassName: PropTypes.string,
  error: PropTypes.string,
  id: PropTypes.string,
  label: PropTypes.string,
  modifiers: PropTypes.arrayOf(PropTypes.string),
  name: PropTypes.string.isRequired
}

export default StandardCheckField
