import React, { PureComponent } from 'react'
import classNames from 'classnames'
import Icon from '../Primitive/Icon'
import Button from '../Primitive/Button'

class BackToTop extends PureComponent {
  constructor () {
    super()
    this.state = { scrollPosition: 'top', footerHeight: 0 }
    this.handleScroll = this.handleScroll.bind(this)
  }

  componentDidMount () {
    this.setState({ footerHeight: document.getElementById('Footer').clientHeight })
    window.addEventListener('scroll', this.handleScroll)
  }

  componentWillUnmount () {
    window.removeEventListener('scroll', this.handleScroll)
  }

  handleScroll (event) {
    const windowScrollHeight = document.documentElement.offsetHeight - screen.height
    const currentScrollHeight = window.pageYOffset
    const switchBottom = windowScrollHeight - this.state.footerHeight + 100

    if (currentScrollHeight > screen.height * 0.5) {
      this.setState({ scrollPosition: 'middle' })

      if (currentScrollHeight >= switchBottom) {
        this.setState({ scrollPosition: 'bottom' })
      }
    } else {
      this.setState({ scrollPosition: 'top' })
    }
  }

  render () {
    return (
      <Button className={classNames('BackToTop', this.state.scrollPosition)} onClick={() => { this.handleScroll() }} href="#top"><Icon type="UpArrow" /></Button>
    )
  }
}

export default BackToTop
