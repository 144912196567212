import React, { PureComponent } from 'react'
import classNames from 'classnames'
import Field from '../Primitive/Field/component/Field'
import ButtonStandard from '../Primitive/ButtonStandard'
import SmartLink from '../Primitive/SmartLink'
import { object } from 'prop-types'

class ApplyForm extends PureComponent {
  constructor (props) {
    super(props)
    this.handleOnChange = this.handleOnChange.bind(this)
    this.handleOnClick = this.handleOnClick.bind(this)
    this.state = {}
  }

  handleOnChange (e) {
    this.setState({
      [e.target.name]: e.target.checked
    })
  }

  handleOnClick () {
    window.open(
      this.props.additionalData.jobLink,
      '_blank'
    )
  }

  render () {
    return (
      <div className={classNames('ApplyForm')}>
        <h2 className="ApplyForm__Title">Start Application</h2>
        <Field
          name="ageConfirmation"
          type="checkbox"
          onChange={this.handleOnChange}
          options={[
            {
              text:
                'Are you at least 18 years of age and are fully eligible to work in the UK?',
              value: 'yes'
            }
          ]}
        />
        <div className="ApplyForm__CheckWrapper">
          <Field
            className="ApplyForm__CheckBox"
            name="termsAndConditions"
            type="checkbox"
            onChange={this.handleOnChange}
            options={[
              {
                text: (
                  <label
                    htmlFor="termsAndConditions"
                    className="ApplyForm__CheckText"
                  >
                    Do you agree to our&nbsp;
                    <SmartLink target="_blank" href="/privacy">
                      Privacy Policy{' '}
                    </SmartLink>
                    and{' '}
                    <SmartLink target="_blank" href="/terms-and-conditions">
                      Terms & Conditions
                    </SmartLink>
                    ?
                  </label>
                ),
                value: 'yes'
              }
            ]}
          />
        </div>
        <div className="ApplyForm__SubmitButton">
          <ButtonStandard
            type="submit"
            value="Submit"
            name="Submit"
            onClick={this.handleOnClick}
            disabled={!(this.state.ageConfirmation && this.state.termsAndConditions)}
          >
            Start application
          </ButtonStandard>
        </div>
      </div>
    )
  }
}

ApplyForm.propTypes = {
  additionalData: object
}

export default ApplyForm
