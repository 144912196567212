import React from 'react'
import ReactDOM from 'react-dom'
import ApplyForm from '../../../site/layout/component/ApplyForm/ApplyForm'
import debug from 'debug'

export default () => {
  document.addEventListener('DOMContentLoaded', () => {
    const appNode = document.getElementById('apply-form')
    if (!appNode) {
      return debug('No apply-form node found. Skipping.')
    }
    const props = JSON.parse(appNode.getAttribute('data-props') || '{}')
    ReactDOM.render(
      <ApplyForm {...props} />,
      appNode
    )
  })
}
