import React from 'react'
import PropTypes from 'prop-types'

import CheckControl from './check/Check'
import TextControl from './text/Text'
import TextareaControl from './textarea/Textarea'

const Control = (props) => {
  const { type } = props
  const componentMap = {
    checkbox: () => <CheckControl {...props} />,
    textarea: () => <TextareaControl {...props} />
  }

  return componentMap[type] ? componentMap[type]() : <TextControl {...props} />
}

Control.propTypes = {
  type: PropTypes.string.isRequired
}

export default Control
