import React, { PureComponent } from 'react'
import { bool, node, string, oneOf } from 'prop-types'
import classNames from 'classnames'
import SmartLink from '../SmartLink'

export class ButtonStandard extends PureComponent {
  render () {
    const { className, disabled, buttonStyle, textButton, ...other } = this.props
    return (
      <SmartLink
        className={classNames(
          'ButtonStandard',
          disabled && 'disabled',
          textButton && 'textbutton',
          buttonStyle,
          className
        )}
        disabled={disabled}
        {...other}
      />
    )
  }
}

ButtonStandard.propTypes = {
  children: node.isRequired,
  className: string,
  buttonStyle: oneOf([ 'secondary', 'red', 'standard' ]),
  disabled: bool,
  textButton: bool
}

export default ButtonStandard
