import React from 'react' // eslint-disable-line no-unused-vars
// Sourced from https://material.io/tools/icons/?style=outline
// svgo ./components/site/layout/component/Primitive/Icon/source/hamburger.svg --enable=removeXMLNS --enable=removeDimensions --enable=removeTitle --enable=removeStyleElement --multipass --pretty --indent=2

// cache-buster 15

import Close from './source/close.svg'
import Hamburger from './source/hamburger.svg'
import Logo from './source/logo.svg'
import ProductR from './source/product-r.svg'
import LinkedIn from './source/linkedIn.svg'
import Twitter from './source/twitter.svg'
import Facebook from './source/facebook.svg'
import UpArrow from './source/up-arrow.svg'
import Bespoke from './source/r-bespoke.svg'
import Energy from './source/r-energy.svg'
import Nursery from './source/r-nursery.svg'
import Publishing from './source/r-publishing.svg'
import Phone from './source/phone.svg'
import Mail from './source/mail.svg'
import LeftArrow from './source/left-arrow.svg'

const Svgs = {
  Close,
  Hamburger,
  Logo,
  ProductR,
  LinkedIn,
  Twitter,
  Facebook,
  UpArrow,
  Bespoke,
  Energy,
  Nursery,
  Publishing,
  Phone,
  Mail,
  LeftArrow
}

export default Svgs
