import React, { Fragment } from 'react'
import PropTypes from 'prop-types'

const debug = require('debug')('form-submission')
const apiPrefix = typeof API_URL === 'undefined' ? '' : API_URL

const create = ({
  Component,
  schema,
  apiUrl,
  errorMessage,
  successMessage,
  data = {},
  props,
  onSuccess
}) => {
  class Container extends React.Component {
    constructor (props) {
      super(props)
      this.handleOnChange = this.handleOnChange.bind(this)
      this.handleOnSubmit = this.handleOnSubmit.bind(this)
      this.createPayload = this.createPayload.bind(this)
      this.setValues = this.setValues.bind(this)
      this.onSuccess = onSuccess
      this.state = {
        waiting: false,
        error: {},
        data: { ...schema.makeDefault(data), ...props.additionalData },
        includesFile: false
      }
    }

    getCheckBoxValues (e) {
      let values = this.state.data[e.currentTarget.name]
      if (!values) values = new Set()
      const newValue = e.currentTarget.value
      values.has(newValue) ? values.delete(newValue) : values.add(newValue)
      return values.size > 0 ? values : null
    }

    handleOnChange (e) {
      debug('onchange', {
        ...this.state.data,
        [e.currentTarget.name]: e.currentTarget.value
      })
      let targetValue
      e.target.type === 'checkbox'
        ? (targetValue = this.getCheckBoxValues(e))
        : (targetValue = e.currentTarget.value)

      if (e.target.type === 'file') {
        this.setState({ includesFile: true })
        targetValue = e.target.files[0]
      }
      this.setState({
        data: { ...this.state.data, [e.currentTarget.name]: targetValue }
      })
    }

    createPayload () {
      const { includesFile, data } = this.state
      const payload = { method: 'POST' }
      if (includesFile) {
        const formData = new FormData()
        for (const name in data) {
          formData.append(name, data[name])
        }
        payload.body = formData
        return payload
      }

      payload.headers = { 'content-type': 'application/json' }
      payload.body = JSON.stringify(this.state.data)
      return payload
    }

    handleOnSubmit (e) {
      e.preventDefault()
      schema.validate(this.state.data, (err, errors) => {
        if (err) return alert(err)
        if (Object.keys(errors).length > 0) {
          return this.setState({ error: errors })
        }
        this.setState({ waiting: true, error: {} })
        fetch(apiPrefix + apiUrl, this.createPayload())
          .then((res) => {
            this.setState({ waiting: false })
            if (res.status === 200) {
              this.onSuccess &&
                this.onSuccess(this.state.data, this.setState.bind(this))
              return this.setState({ successMessage })
            }
            this.setState({ errorMessage, error: { error: errorMessage } })
          })
          .catch((err) => {
            this.setState({ waiting: false })
            this.setState({
              errorMessage:
                'There was a problem, try again later: ' + err.message,
              error: { error: err.message }
            })
          })
      })
    }

    setValues (values) {
      this.setState({
        data: { ...this.state.data, ...values }
      })
    }

    render () {
      return (
        <>
          <Component
            onChange={this.handleOnChange}
            onSubmit={this.handleOnSubmit}
            setValues={this.setValues}
            {...props}
            {...this.props}
            {...this.state}
          />
        </>
      )
    }
  }

  Container.propTypes = {
    additionalData: PropTypes.object
  }

  return Container
}

export default create
