import React, { PureComponent } from 'react'
import { bool, node, string } from 'prop-types'
import classNames from 'classnames'
import SmartLink from '../SmartLink'

export class Button extends PureComponent {
  render () {
    const { className, disabled, textButton, scrollPosition, ...other } = this.props
    return (
      <SmartLink
        className={classNames(
          'Button',
          disabled && 'disabled',
          textButton && 'textbutton',
          scrollPosition,
          className
        )}
        disabled={disabled}
        {...other}
      />
    )
  }
}

Button.displayName = 'Button'

Button.propTypes = {
  children: node.isRequired,
  className: string,
  disabled: bool,
  scrollPosition: string,
  textButton: bool
}

export default Button
